import React from 'react';
import AdminLayout from '../../../components/AdminLayout';
import {
  getBitacoraError,
  changeStatus,
  updateData,
  validateCode
} from "../../../api/users";
import { validateName, validateEmail } from "../../../components/helper/regex";
import { Space, Table, Modal, Button, Input, } from 'antd';
import SCButton from '../../../components/SCButton';
import Spinner from '../../../components/Spinner';
import { AiOutlineWarning } from "@react-icons/all-files/ai/AiOutlineWarning"


const Bitacora = ({ location }) => {
  const [edit, setEdit] = React.useState(false)
  const [filtraRegistros, setFiltraRegistros] = React.useState()
  const [isLoading, setIsLoading] = React.useState(false)
  const [registros, setRegistros] = React.useState([])
  const [putRegistros, setPutRegistros] = React.useState()
  const [placeHolderInfo, setPlaceHolderInfo] = React.useState()
  const [userInfo, setUserInfo] = React.useState({
    id: "",
    Nombre: "",
    Apellido: "",
    Email: "",
    Codigo: "",
    Empresa: ""
  })

  const [error, setError] = React.useState()

  const columns = [
    {
      title: "Nombre",
      dataIndex: "Nombre",
      key: "1"
    }, {
      title: "Apellidos",
      dataIndex: "Apellido",
      key: "2"
    }, {
      title: "Email",
      dataIndex: "Email",
      key: "3"
    }, {
      title: "Códigos",
      dataIndex: "Codigo",
      key: "4"
    },
    {
      title: "Empresa",
      dataIndex: "Empresa",
      key: "5"
    }, {
      title: "Acción",
      dataIndex: "",
      key: "6",
      render: (text, record) => (
        <Space size="middle">


          <SCButton>
            <p onClick={() => {
              console.log(record)
              setUserInfo({
                id: record.id,
                Nombre: record.Nombre,
                Apellido: record.Apellido,
                Email: record.Email,
                Codigo: record.Codigo,
                Empresa: record.Empresa
              })
              setError(record.detalleError)
              showModal()
            }} className='mb-0 px-1 font-semibold text-xs'>Editar y Registrar</p>
          </SCButton>

          <SCButton>
            <p onClick={() => {
              onSkip(record.id, location.pathname.split("/")[3], registros)
            }} className='mb-0 px-2 font-semibold text-xs'>Omitir</p>
          </SCButton>
        </Space>



      ),
    },
  ]

  const { confirm } = Modal

  const [isModalVisible, setIsModalVisible] = React.useState(false)

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    if (validateEmail(userInfo.Email)) {
      // setUserInfo({
      //   ...userInfo,
      //   id: placeHolderInfo.id,
      //   Email: userInfo.Email === "" ? placeHolderInfo.Email : userInfo.Email,
      //   Nombre: userInfo.Nombre === "" ? placeHolderInfo.Nombre : userInfo.Nombre,
      //   Apellido: userInfo.Apellido === "" ? placeHolderInfo.Apellido : userInfo.Apellido,
      //   // Codigo : userInfo.Codigo === "" ? placeHolderInfo.Codigo : userInfo.Codigo,
      // })
      // if (userInfo.Codigo === "") {
      //   return setError("Ingrese un código")
      // }
      if(userInfo.Nombre === ""){
        return setError("Ingrese un Nombre")
      }
      if(userInfo.Apellido === ""){
        return setError("Ingrese un Apellido")
      }
      
      setEdit(true)
    }else{
      setError("Tipo de Email inválido")
    }

  };



  const handleCancel = () => {
    setUserInfo({
      id: "",
      Nombre: "",
      Apellido: "",
      Email: "",
      Codigo: ""
    })
    setError()
    setIsModalVisible(false);
  };

  const onSkip = (idPropiedad, idBitacora, registros) => {

    registros.forEach(item => {
      if (item.id === idPropiedad) {
        item.status = "omitido"
      }
    })

    setPutRegistros({ ...putRegistros, ["registroCSVUsuario"]: registros })
    changeStatus(idBitacora, putRegistros).then(response => {
      setRegistros([])
      response.registroCSVUsuario.forEach(element => {
        if (element.status === "error") {
          setRegistros(registros => [...registros, element]);
        }
      });
      setPutRegistros(response)
    })
      .catch(error => {
        console.log(error)
      })

  }
  const onSkipAll = () => {
    confirm({
      icon: null,
      content: <>
        <div className='flex flex-col'>
          <div className='flex items-center justify-center'>
            <AiOutlineWarning className='text-amber-400' size={22} />
            <p className='mb-0 pl-3 text-center font-bold'>¿Omitir todos los registros?</p>
          </div>
          <p className='text-red-600 mb-0 text-center mt-4'>Esta acción no se puede deshacer.</p>
        </div>
      </>,
      onOk() {

        let idBitacora = location.pathname.split("/")[3]
        registros.forEach(item => {
          item.status = "omitido"
        })
        setPutRegistros({ ...putRegistros, ["registroCSVUsuario"]: registros })
        changeStatus(idBitacora, putRegistros).then(response => {
          setRegistros([])
          response.registroCSVUsuario.forEach(element => {
            if (element.status === "error") {
              setRegistros(registros => [...registros, element]);
            }
          });
          setPutRegistros(response)
        })
          .catch(error => {
            console.log(error)
          })
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }


  React.useEffect(() => {
    getBitacoraError(location.pathname.split("/")[3]).then(response => {
      setPutRegistros(response)
      setFiltraRegistros(response.registroCSVUsuario)
    })
  }, []);

  React.useEffect(() => {
    if (filtraRegistros) {
      filtraRegistros.forEach(element => {
        if (element.status === "error") {
          setRegistros(registros => [...registros, element]);
        }
      });
      setIsLoading(true)
    }
  }, [filtraRegistros]);

  React.useEffect(() => {
    if (edit) {
      registros.forEach(item => {
        if (item.id === userInfo.id) {
          console.log(item)
          item.Nombre = userInfo.Nombre
          item.Apellido = userInfo.Apellido
          item.Email = userInfo.Email
          item.Codigo = userInfo.Codigo
        }
      })
      setPutRegistros({ ...putRegistros, ["registroCSVUsuario"]: registros })

      updateData(putRegistros, userInfo.Codigo + "_*&_" + userInfo.id + "_*&_" + location.pathname.split("/")[3]).then(response => {
        if (response.ok) {
          setRegistros([])
          getBitacoraError(location.pathname.split("/")[3]).then(response => {
            setPutRegistros(response)
            setFiltraRegistros(response.registroCSVUsuario)
          })
          return handleCancel()
        }

        setError(response.message)

      })

    }
    setEdit(false)
  }, [edit]);

  const updateProperty = (property, value) => {
    if (property === "Nombre" || property === "Apellido") {
      if (validateName(value.charAt(value.length - 1)) || value === "") {
        setError()
        return setUserInfo({ ...userInfo, [property]: value })
      }
      return setError("Solo se permite letras")
    } else if (property === "Codigo") {
      return setUserInfo({ ...userInfo, [property]: value })
    }
   else if (property === "Empresa") {
    return setUserInfo({ ...userInfo, [property]: value })
  }
   else if (property === "Email") {
      if(error) setError()
      return setUserInfo({ ...userInfo, [property]: value })
    }
  }

  return <AdminLayout>
    <div className='flex justify-between'>
      <h2 className='text-2xl cursor-default font-bold'>{registros.length} Registros Pendientes</h2>
      <div className='w-max h-4'>
        <SCButton>
          <p onClick={onSkipAll} className='mb-0 px-6 font-semibold'>Omitir Todos</p>
        </SCButton>
      </div>
    </div>
    {
      isLoading ?
        <Table
          className='mt-4 cursor-pointer'
          columns={columns}
          dataSource={registros}
        >
        </Table> : <Spinner size={42} />
    }
    <Modal title="Modificar Usuario" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
      <div>
        <div>
          <label>Nombre: </label>
          <Input placeholder={placeHolderInfo ? placeHolderInfo.Nombre : null} value={userInfo.Nombre} onChange={({ target }) => { updateProperty("Nombre", target.value) }} />
        </div>
        <div>
          <label>Apellido: </label>
          <Input placeholder={placeHolderInfo ? placeHolderInfo.Apellido : null} value={userInfo.Apellido} onChange={({ target }) => { updateProperty("Apellido", target.value) }} />
        </div>
        <div>
          <label>Email: </label>
          <Input placeholder={placeHolderInfo ? placeHolderInfo.Email : null} value={userInfo.Email} onChange={({ target }) => { updateProperty("Email", target.value) }} />
        </div>
        <div>
          <label>Código: </label>
          <Input placeholder={placeHolderInfo ? placeHolderInfo.Codigo : null} value={userInfo.Codigo} onChange={({ target }) => { updateProperty("Codigo", target.value) }} />
        </div>
        <div>
          <label>Empresa: </label>
          <Input placeholder={placeHolderInfo ? placeHolderInfo.Empresa : null} value={userInfo.Empresa} onChange={({ target }) => { updateProperty("Empresa", target.value) }} />
        </div>
        {
          error ? <p className='mb-0 my-4 text-center text-red-700 font-semibold'>{error}</p> : null
        }
      </div>
    </Modal>
  </AdminLayout>;
}

export default Bitacora;
