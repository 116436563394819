const regexName = /^[a-zA-Z\u00C0-\u017F\s]/;


export const validateName = name => {
    return regexName.test(name)
}

export const validateEmail = word => {
    const email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/
    return email.test(String(word).toLowerCase())
  }